const QUERIES = {
  USERS_LIST: 'users-list',
  COMPANIES_LIST: 'companies-list',
  COMPANY_DETAILS: 'company-details',
  SITES_LIST: 'sites-list',
  WAYBILLS_LIST: 'waybills-list',
  HEARTBEAT: 'heartbeat',
  BATCH_LIST: 'batch-list',
  BATCH_STATUS: 'batch-status',
  STOCK_LIST: 'stock-list',
  JOBS_LIST: 'JOBS_LIST',
  JOB_DETAILS: 'JOB_DETAILS',
  JOB_STATUS: 'JOB_STATUS',
  STOCK_DETAILS: 'STOCK_DETAILS',
  STOCK_EVENTS_LIST: 'STOCK_EVENTS_LIST',
}

enum EBATCH_STATUS {
  STATUS_UNKNOWN = 'STATUS_UNKNOWN',
  STATUS_PROCESSING_BATCH = 'STATUS_PROCESSING_BATCH',
  STATUS_NOT_PROCESSING_BATCH = 'STATUS_NOT_PROCESSING_BATCH',
}

export {QUERIES, EBATCH_STATUS}
