import {API} from 'aws-amplify'
import {UserModel} from '../../../../auth'
import {Job} from './_models'

export const list = async (currentUser: UserModel, query: string) => {
  return API.get('app_base', `/organization/${currentUser.currentOrganization}/job?${query}`, {})
}

export const get = async (id: string, currentUser: UserModel) => {
  const encodedUrl = encodeURIComponent(`/organization/${currentUser.currentOrganization}/job/${id}`)
  return API.get('app_base', encodedUrl, {})
}

export const create = async (currentUser: UserModel, job: Job) => {
  const encodedUrl = encodeURIComponent(`/organization/${currentUser.currentOrganization}/job`)
  return API.post('app_base', encodedUrl, {body: job})
}

export const update = async (currentUser: UserModel, job: Job) => {
  const encodedUrl = encodeURIComponent(`/organization/${currentUser.currentOrganization}/job/${job.id}`)
  return API.put('app_base', encodedUrl, {body: job})
}

export const issue = async (currentUser: UserModel, id: string, jobCardNumber: string) => {
  const url = `/organization/${currentUser.currentOrganization}/job/${id}/issue`
  return API.post('app_base', url, {body: {jobCardNumber}})
}

export const close = async (currentUser: UserModel, id: string) => {
  const encodedUrl = encodeURIComponent(`/organization/${currentUser.currentOrganization}/job/${id}/close`)
  return API.post('app_base', encodedUrl, {body: {}})
}

export const jobsReport = async (currentUser: UserModel) => {
  const url = `/organization/${currentUser.currentOrganization}/job/detailed-report`
  return API.post('app_base', url, {})
}
