import React, {FC} from 'react'
import {ListLoading} from '../../../../../../_metronic/common/table/loading/ListLoading'
import {useAuth} from '../../../../auth'
import {useListView} from '../../../../../../_metronic/common/table/core/ListViewProvider'
import {QUERIES, isNotEmpty} from '../../../../../../_metronic/helpers'
import {useQuery} from 'react-query'
import {getJobById} from '../core/_requests'
import {JobIssueStockModalForm} from './JobIssueStockModalForm'
import {EditModal} from '../../../../../../_metronic/common/edit-modal/EditModal'
import {Job} from '../core/_models'

type Props = {
  job: Job
}

export const JobIssueStockModalFormWrapper: FC<Props> = ({job}) => {
  const {currentUser} = useAuth()

  const {itemIdForUpdate, setItemIdForUpdate} = useListView()
  const enabledQuery: boolean = isNotEmpty(itemIdForUpdate)

  return (
    <>
      <JobIssueStockModalForm job={job} />
    </>
  )
}
