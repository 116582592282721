import React, {useEffect, useState} from 'react'
import {ListViewProvider, useListView} from '../../../../../_metronic/common/table/core/ListViewProvider'
import {useAuth} from '../../../auth'
import {useParams} from 'react-router-dom'
import {EACTION_TYPE, QUERIES, isNotEmpty} from '../../../../../_metronic/helpers'
import {useQuery} from 'react-query'
import {getJobById} from '../jobs-list/core/_requests'
import {ListLoading} from '../../../../../_metronic/common/table/loading/ListLoading'
import {JobHeader} from './JobHeader'
import {JobBody} from './JobBody'
import {JobIssueStockModalFormWrapper} from '../jobs-list/job-issue-stock-modal/JobIssueStockModalFormWrapper'
import {EditModal} from '../../../../../_metronic/common/edit-modal/EditModal'

export const JobDetails = () => {
  const {currentUser} = useAuth()
  const {jobId} = useParams()
  const [enabledQuery, setEnabledQuery] = useState<boolean>(isNotEmpty(jobId))
  const {itemIdForUpdate, setAction, action} = useListView()

  useEffect(() => {
    setEnabledQuery(isNotEmpty(jobId))
  }, [jobId])

  const {isLoading, data: job} = useQuery(
    `${QUERIES.JOB_DETAILS}-job-${jobId}`,
    () => {
      return getJobById(jobId, currentUser)
    },
    {
      cacheTime: 0,
      enabled: enabledQuery,
      onError: (err) => {
        console.error(err)
      },
    }
  )
  // const {itemIdForUpdate, action} = useListView()
  return (
    <>
      {isLoading ? (
        <>
          <ListLoading />
        </>
      ) : (
        <>
          <JobHeader job={job} isLoading={isLoading} />
          <JobBody job={job} isLoading={isLoading} />
          {action !== undefined && job !== undefined && action?.type === EACTION_TYPE.ACTION_ISSUE_STOCK && (
            <>
              <EditModal title={`Issue Stock - ${job?.projectName}`}>
                <JobIssueStockModalFormWrapper job={job} />
              </EditModal>
            </>
          )}
        </>
      )}
    </>
  )
}

export const JobWrapper = () => {
  return (
    <>
      <ListViewProvider>
        <JobDetails />
      </ListViewProvider>
    </>
  )
}
