import React, {FC, useState} from 'react'
import {useQueryClient} from 'react-query'
import * as Yup from 'yup'
import {useListView} from '../../../../../../_metronic/common/table/core/ListViewProvider'
import {useQueryResponse} from '../../../../../../_metronic/common/table/core/QueryResponseProvider'
import {useAuth} from '../../../../auth'
import {useFormik} from 'formik'
import {EditFormInput} from '../../../../../../_metronic/common/edit-modal/EditFormInput'
import {ListLoading} from '../../../../../../_metronic/common/table/loading/ListLoading'
import {issueStockForJob} from '../core/_requests'
import {Job} from '../core/_models'
import {QUERIES} from '../../../../../../_metronic/helpers'

type Props = {
  job: Job
}

const issueStockSchema = Yup.object().shape({
  jobCardNumber: Yup.string().required('A Job Card number is required'),
})

export const JobIssueStockModalForm: FC<Props> = ({job}) => {
  const {setAction} = useListView()
  const {refetch} = useQueryResponse()
  const [error, setError] = useState<string | null>(null)
  const {currentUser} = useAuth()
  const queryClient = useQueryClient()

  const [jobCardActionState] = useState<{jobCardNumber: string}>({
    jobCardNumber: '',
  })

  const cancel = (withRefresh?: boolean) => {
    if (withRefresh) {
      refetch()
    }
    setAction(undefined)
  }

  const formik = useFormik({
    initialValues: jobCardActionState,
    validationSchema: issueStockSchema,
    onSubmit: async (values, {setSubmitting}) => {
      setSubmitting(true)
      try {
        setSubmitting(true)
        await issueStockForJob(currentUser, job.id, values.jobCardNumber)
        await queryClient.refetchQueries(`${QUERIES.STOCK_EVENTS_LIST}-job-${job.id}`)
        await queryClient.refetchQueries(`${QUERIES.JOB_DETAILS}-job-${job.id}`)
        setError(null)
        cancel(true)
      } catch (ex: any) {
        console.error(ex)
        // setError(ex.response.data.message)
      } finally {
        setSubmitting(false)
      }
    },
  })

  return (
    <>
      {error && <div className='alert alert-danger'>{error}</div>}
      <form id='kt_modal_add_user_form' className='form' onSubmit={formik.handleSubmit} noValidate>
        <div className='row'>
          <div className='col-md-12'>
            <EditFormInput label='Job Card Number' name='jobCardNumber' fieldProps={formik.getFieldProps('jobCardNumber')} touchedProp={formik.touched.jobCardNumber} errorsProp={formik.errors.jobCardNumber} isSubmitting={formik.isSubmitting} isLoading={false} />
          </div>
          <div className='col-md-12'></div>
        </div>

        {/* begin::Actions */}
        <div className='text-center pt-15'>
          <button type='reset' onClick={() => cancel()} className='btn btn-light me-3' data-kt-users-modal-action='cancel' disabled={formik.isSubmitting}>
            Discard
          </button>

          <button type='submit' className='btn btn-primary' data-kt-users-modal-action='submit' disabled={formik.isSubmitting || !(formik.isValid && formik.dirty) || !formik.touched}>
            <span className='indicator-label'>Submit</span>
            {formik.isSubmitting && (
              <span className='indicator-progress'>
                Please wait... <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
              </span>
            )}
          </button>
        </div>
        {/* end::Actions */}
      </form>
      {formik.isSubmitting && <ListLoading />}
    </>
  )
}
