import React, {useState} from 'react'
import {KTSVG} from '../../../../../../../_metronic/helpers'
import {useListView} from '../../../../../../../_metronic/common/table/core/ListViewProvider'
import {useAuth} from '../../../../../auth'
import {detailedJobsReport} from '../../core/_requests'

export const JobListToolbar = () => {
  const [isSubmitting, setSubmitting] = useState(false)
  const [submitted, setSubmitted] = useState(false)
  const {setItemIdForUpdate} = useListView()
  const {isSuperAdmin, isAdmin, isStockClerk, currentUser} = useAuth()
  const openJobModal = () => {
    setItemIdForUpdate(null)
  }

  const report = async () => {
    try {
      setSubmitting(true)
      await detailedJobsReport(currentUser)
      setSubmitted(true)
      setTimeout(() => {
        setSubmitted(false)
      }, 5000)
    } catch (ex: any) {
      console.error(ex)
    } finally {
      setSubmitting(false)
    }
  }

  return (
    <>
      {(isSuperAdmin() || isAdmin() || isStockClerk()) && (
        <div className='d-flex justify-content-end' data-kt-user-table-toolbar='base'>
          <button type='button' className='btn btn-sm btn-primary' onClick={report} disabled={isSubmitting || submitted}>
            {!isSubmitting && !submitted && 'Detailed Jobs Report'}
            {!isSubmitting && submitted && 'Detailed Jobs Report Requested'}
            {isSubmitting && (
              <span className='indicator-progress' style={{display: 'block'}}>
                Detailed Jobs Report Generating... <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
              </span>
            )}
          </button>
          <button type='button' className='btn btn-sm btn-primary ms-3' onClick={openJobModal}>
            <KTSVG path='/media/icons/duotune/arrows/arr075.svg' className='svg-icon-2' />
            Add Job
          </button>
          {/* end::Add user */}
        </div>
      )}
    </>
  )
}
