import {Route, Routes, Outlet, Navigate} from 'react-router-dom'
import {PageLink, PageTitle} from '../../../../_metronic/layout/core'
import {logger} from '../../../../_metronic/helpers/Logger'
import {JobsListWrapper} from './jobs-list/JobsList'
import {JobDetails, JobWrapper} from './job-details/JobDetails'

const jobsBreadcrumbs: Array<PageLink> = [
  {
    title: 'Job Management',
    path: '/apps/job-management',
    isSeparator: false,
    isActive: false,
  },
  {
    title: '',
    path: '',
    isSeparator: true,
    isActive: false,
  },
]

const JobsPage = () => {
  logger.debug('jobs page')

  return (
    <Routes>
      <Route element={<Outlet />}>
        <Route
          path='jobs'
          element={
            <>
              <PageTitle breadcrumbs={jobsBreadcrumbs}>Jobs list</PageTitle>
              <JobsListWrapper></JobsListWrapper>
            </>
          }
        />
      </Route>
      <Route
        path='job/:jobId'
        element={
          <>
            <PageTitle breadcrumbs={jobsBreadcrumbs}>Job details</PageTitle>
            <JobWrapper />
          </>
        }
      ></Route>
      <Route index element={<Navigate to='/apps/job-management/jobs' />} />
    </Routes>
  )
}

export default JobsPage
