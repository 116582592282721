import {Column, HeaderProps} from 'react-table'
import {CustomHeader} from '../../../../../../../_metronic/common/table/columns/CustomHeader'
import {StockEvent} from '../../core/_model'
import {StockEventActionsCell} from './StockEventActionsCell'
import {PropsWithChildren} from 'react'
import {JobLinkCell} from './JobLinkCell'
import {StockLinkCell} from '../../../stock-list/table/columns/StockLinkCell'

const actions = ({...props}) => {
  return <>{props.data[props.row.index].entityType === 'STOCK_EVENT_ALLOCATE' && <StockEventActionsCell id={props.data[props.row.index].id} stockEvent={props.data[props.row.index]} />}</>
}

const getStockEventColumns = () => {
  return [
    ...stockEventColumns,
    {
      Header: (props: PropsWithChildren<HeaderProps<any>>) => <CustomHeader tableProps={props} title='Project Name' className='min-w-100px' />,
      id: 'jobName',
      Cell: ({...props}) => <JobLinkCell id={props.data[props.row.index].jobId} jobName={props.data[props.row.index].jobName ?? ''} />,
    },
    {
      Header: (props: PropsWithChildren<HeaderProps<any>>) => <CustomHeader tableProps={props} title='User' className='min-w-100px' />,
      accessor: 'created_by',
    },
    {
      Header: (props: PropsWithChildren<HeaderProps<any>>) => <CustomHeader tableProps={props} title='Actions' className='text-end min-w-100px' />,
      id: 'actions',
      Cell: actions,
    },
  ]
}

const getStockEventColumnsForJob = () => {
  const stockEventColumnsForJob: any[] = [
    {
      Header: (props: PropsWithChildren<HeaderProps<any>>) => <CustomHeader tableProps={props} title='Name' className='min-w-125px' />,
      id: 'stockName',
      Cell: ({...props}) => (
        <div>
          {' '}
          <StockLinkCell name={props.data[props.row.index].stockName} id={props.data[props.row.index].stockId} />{' '}
        </div>
      ),
    },
    ...stockEventColumns,
    {
      Header: (props: PropsWithChildren<HeaderProps<any>>) => <CustomHeader tableProps={props} title='User' className='min-w-100px' />,
      accessor: 'created_by',
    },
    {
      Header: (props: PropsWithChildren<HeaderProps<any>>) => <CustomHeader tableProps={props} title='Job Card' className='min-w-100px' />,
      accessor: 'jobCardNumber',
    },
    {
      Header: (props: PropsWithChildren<HeaderProps<any>>) => <CustomHeader tableProps={props} title='Actions' className='text-end min-w-100px' />,
      id: 'actions',
      Cell: actions,
    },
  ]
  return stockEventColumnsForJob
}

const stockEventColumns: ReadonlyArray<Column<StockEvent>> = [
  {
    Header: (props) => <CustomHeader tableProps={props} title='Date' className='min-w-125px' />,
    id: 'created_at',
    Cell: ({...props}) => <div> {props.data[props.row.index].created_at.substring(0, 10)} </div>,
  },
  // {
  //   Header: (props) => <CustomHeader tableProps={props} title='Name' className='min-w-125px' />,
  //   accessor: 'stockName',
  // },
  {
    Header: (props) => <CustomHeader tableProps={props} title='Type' className='min-w-125px' />,
    accessor: 'entityType',
  },
  {
    Header: (props) => <CustomHeader tableProps={props} title='Event Quantity' className='min-w-125px' />,
    accessor: 'quantity',
  },
  {
    Header: (props) => <CustomHeader tableProps={props} title='Unit Price' className='min-w-125px' />,
    accessor: 'unitPrice',
    Cell: (props) => <div> R {props.value?.toFixed(2)} </div>,
  },
  {
    Header: (props) => <CustomHeader tableProps={props} title='Cost' className='min-w-125px' />,
    id: 'Cost',
    Cell: ({...props}) => <div> R {(props.data[props.row.index].quantity * props.data[props.row.index].unitPrice).toFixed(2)} </div>,
  },
]

export {stockEventColumns, getStockEventColumnsForJob, getStockEventColumns}
